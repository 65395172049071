<div class="container auth-nav">
    <div class="row">
        <div class="col-md-4 col-sm-2"></div>
        <div class="col-md-4 col-sm-8">
            <div class="row">

                    <a class="logo-1" routerLink="/"></a>

            </div>
            <div class="card-auth">
                <div class="row">
                    <div class="col-md-12">

                        <ul class="menu">
                            <li><a [routerLinkActive]="'selected'" routerLink="/auth/login">Entrar</a></li>
                            <li><a [routerLinkActive]="'selected'" routerLink="/auth/signup">Registar</a></li>
                        </ul>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <router-outlet></router-outlet>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


