<div class="signup-login">
  <div class="row" *ngIf="isModal">
    <div class="col-md-12">
      <div class="modal-header">
        <h3>Registo</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">

      <form (ngSubmit)="signup()" [formGroup]="form">
        <div class="form-group" [ngClass]="{'has-danger': validateField('email')}">
          <!-- <label class="form-control-label">Email address</label> -->
          <input appFocus formControlName="email" type="email" class="form-control" placeholder="Introduza o seu email">
          <div *ngIf="validateField('email')" class="form-control-feedback">Email inválido</div>
        </div>
        <div class="form-group" [ngClass]="{'has-danger': validateField('password')}">
          <!-- <label class="form-control-label">Password</label> -->
          <input formControlName="password" type="password" class="form-control" placeholder="Escolha uma password">
          <div *ngIf="validateField('password')" class="form-control-feedback">Password obrigatória</div>
        </div>
        <div class="form-check" class="more-options checkbox" [ngClass]="{'has-danger': validateField('terms')}">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" formControlName="terms">
            Li e aceito os <a target="_blank" routerLink="/terms">termos e condições</a>
          </label>
          <div *ngIf="validateField('terms')" class="form-control-feedback">Tem de aceitar os termos e condições para continuar</div>
        </div>
        <div class="form-options">
          <button class="btn btn-primary" [disabled]="loading" type="submit">REGISTAR</button>
        </div>
      </form>

      <div class="more-options">
        <p>Já tem conta criada? <a routerLink="/auth/login" (click)="closeModal()">Entre aqui</a></p>
      </div>

      <div class="alert alert-danger" role="alert" *ngIf="error">
        <strong>Atenção!</strong> {{error}}
      </div>

    </div>
  </div>
</div>