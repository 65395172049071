
<div class="landingpage">

    <div class="navbar-adv">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <a routerLink="/">
                        <span class="logo-2"></span>
                    </a>
                </div>
                <div class="col-md-6">
                    <nav class="main-nav hidden-md-down">
                        <ul>
                            <li><a scrollTo href="#howitworks">COMO FUNCIONA</a></li>

                            <li><a (click)="launchSignup()">EXPERIMENTE GRATUITAMENTE</a></li>

                            <li><a target="_blank" routerLink="/faq">FAQs</a></li>

                            <li class="login ico-login"><a routerLink="/auth/login">ENTRAR</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>

    <div class="hero">
        <div class="fadebg"></div>
        <div class="container-fluid">
            <div class="col-md-12 text-center">
                <h1>Serviço de Alertas em Processos de <span>Insolvência e PERs</span></h1>
                <p><a (click)="launchSignup()">Experimente gratuitamente</a> e receba um alerta no seu e-mail assim que haja um anúncio publicitado no <a target="_blank" href="https://www.citius.mj.pt/portal/consultas/ConsultasCire.aspx">Citius</a> relativo ao seu processo.</p>
                <div class="row">
                    <div class="col-md-4 offset-md-4 col-xm-12 mailbox">
                      <form (submit)="launchSignup()" class="form-inline">
                          <!-- <label for="inputEmail" class="sr-only">E-mail</label> -->
                          <input appFocus [(ngModel)]="emailAddress" name="email" type="email" class="form-control" placeholder="Introduza o seu e-mail" aria-label="O seu e-mail">
                          <button type="submit" class="btn btn-white">Criar Conta</button>
                      </form>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container desktop">
        <div class="row">
            <a href="/register"></a><img class="img-desktop" src="../assets/imgs/desktop.png">
            <!-- <div class="img-desktop"></div> -->
        </div>
    </div>

    <div class="container-fluid howitworks" id="howitworks">
        <div class="row">
            <h1 class="title">Como Funciona</h1>
        </div>
        <div class="row">
            <img class="img-iphone hidden-md-down" src="../assets/imgs/iphone.png">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="box">
                    <span class="num">1</span>
                    <h3>Crie uma conta com o seu e-mail.</h3>
                    <p>A sua conta da-lhe acesso ao sistema de gestão de alertas.</p>
                </div>
                <div class="box">
                    <span class="num">2</span>
                    <h3>Introduza um ou mais NIF/NIPC para o sistema monitorizar.</h3>
                    <p>Crie uma lista de NIF/NIPC que pretende pesquisar de forma organizada e descomplicada.</p>
                </div>
                <div class="box">
                    <span class="num">3</span>
                    <h3>Receba um alerta no seu e-mail</h3>
                    <p>Assim que haja um anúncio publicitado no Citius relativo ao NIF/NIPC pesquisado receberá um e-mail com os elementos sobre o acto publicitado.</p>
                </div>
                <a class="btn btn-green btn-lg" (click)="launchSignup()">Experimente Gratuitamente</a>
            </div>
        </div>
    </div>
<!--
    <div class="prices" id="plans">
        <div class="container-fluid">
            <div class="row">
                <h1 class="title">Planos & Preços</h1>
            </div>
            <div class="row">
                <div class="col-md-4 price-box text-center" *ngFor="let plan of availablePlans">
                    <h3>{{plan.name}}</h3>
                    <p class="desc" *ngIf="plan.totalNifs"> {{ plan.totalNifs }} NIF/NIPC</p>
                    <p class="desc" *ngIf="!plan.totalNifs">NIF/NIPC Ilimitados</p>
                    <div class="price-tag">
                        <p class="price">{{plan.amount / 100}}<span class="currency">€</span></p>
                        <span class="per">por mês</span>
                    </div>
                    <a class="btn btn-cta" (click)="launchSignup(plan.id)">Escolher plano</a>
                </div>

            </div>
        </div>
    </div>
 -->

    <div class="contacts">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h1 class="title">Contacte-nos</h1>
                    <p>Em caso de dúvida ou necessidade de personalização de plano<br/> não hesite em contactar-nos para <a target="_blank" href="mailto:geral@avisoslegais.pt">geral@avisoslegais.pt</a></p>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <span class="logo-2"></span>
                </div>
                <div class="col-md-6">
                    <nav class="footer-nav">
                        <ul class="menu-footer">
                            <li><a target="_blank" routerLink="/terms">Termos e Condições</a></li>
                            <li><a target="_blank" routerLink="/faq">Perguntas Frequentes</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>

</div>
