<div class="signup-login">
  <div class="row" *ngIf="isModal">
    <div class="col-md-12">
      <div class="modal-header">
        <h3>ENTRAR</h3>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 clearfix">

      <form (ngSubmit)="login()" [formGroup]="form">
        <div class="form-group" [ngClass]="{'has-danger': validateField('email')}">
          <input appFocus formControlName="email" type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Introduza o seu email">
          <div *ngIf="validateField('email')" class="form-control-feedback">Email inválido</div>
        </div>
        <div class="form-group" [ngClass]="{'has-danger': validateField('password')}">
          <input formControlName="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
          <div *ngIf="validateField('password')" class="form-control-feedback">Password obrigatória</div>
        </div>
        <div class="form-options">
          <button *ngIf="loading" disabled type="submit" class="btn btn-primary"><i class="fa fa-circle-o-notch rotating"></i></button>
          <button *ngIf="!loading" type="submit" class="btn btn-primary">ENTRAR</button>
        </div>
      </form>

      <div class="more-options">
        <p>Não se lembra da password? <a routerLink="/recoverpassword">Recuperar Password</a> ou <a routerLink="/auth/signup">crie uma conta.</a></p>
      </div>

      <div class="alert alert-danger" role="alert" *ngIf="error">
        <strong>Atenção!</strong> {{error}}
      </div>

    </div>
  </div>
</div>
