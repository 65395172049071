<div class="container auth-nav">
    <div class="row">
        <div class="col-md-4 col-sm-2"></div>
        <div class="col-md-4 col-sm-8">
            <div class="row">

                <a class="logo-1" routerLink="/"></a>

            </div>
            <div class="card-auth">
                <div class="row">
                    <div class="col-md-12">

                      <div class="signup-login">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="modal-header">
                              <h3 *ngIf="sendEmailForm">Recuperar password</h3>
                              <h3 *ngIf="changePasswordForm">Nova password</h3>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">

                            <form (ngSubmit)="sendResetEmail()" [formGroup]="sendEmailForm" *ngIf="sendEmailForm">
                              <div class="form-group" [ngClass]="{'has-danger': validateField('email')}">
                                <label class="form-control-label">E-mail da sua conta</label>
                                <input formControlName="email" type="email" class="form-control" placeholder="Introduza o seu e-mail">
                                <div *ngIf="validateField('email')" class="form-control-feedback">E-mail inválido</div>
                              </div>
                              <div class="form-options">
                                <button [disabled]="loading" type="submit" class="btn btn-primary">RECUPERAR</button>
                              </div>
                            </form>

                            <form (ngSubmit)="saveNewPassword()" [formGroup]="changePasswordForm" *ngIf="changePasswordForm">
                              <div class="form-group" [ngClass]="{'has-danger': !changePasswordForm.get('password').valid && (changePasswordForm.get('password').dirty || submitAttempt)}">
                                <!-- <label class="form-control-label">New Password</label> -->
                                <input formControlName="password" type="password" class="form-control" placeholder="Nova password">
                                <div *ngIf="!changePasswordForm.get('password').valid && (changePasswordForm.get('password').dirty || submitAttempt)" class="form-control-feedback">Invalid E-mail</div>
                              </div>
                              <div class="form-options">
                                <button [disabled]="loading" type="submit" class="btn btn-primary">CONFIRMAR</button>
                              </div>
                            </form>


                          <div class="alert alert-danger" role="alert" *ngIf="error">
                            <strong>Atenção!</strong> {{error}}
                          </div>
                          <div class="alert alert-success" role="alert" *ngIf="showEmailSuccessMsg">
                            <strong>Obrigado</strong> Foi enviada uma mensagem para o seu e-mail com instruções para alterar a sua password
                          </div>
                          <div class="alert alert-success" role="alert" *ngIf="showChangeSuccessMsg">
                            <strong>Obrigado</strong> A sua password foi alterada com sucesso, faça login aqui: <a routerLink="/auth/login">Login</a>
                          </div>

                          </div>
                        </div>
                      </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
