<div class="main">
    <div class="container faq">
        <div class="row">
            <div class="col-md-12">

                <div *ngIf="isLanding">
                    <a class="logo-1" routerLink="/"></a>
                </div>
                <br /><br />
                <h1>Perguntas Frequentes</h1>

                <ul>

                    <li>
                        <h3>1. Como funciona o Avisos Legais?</h3>
                        <p>
                            O serviço disponibilizado pelo Avisos Legais permite que os nossos Clientes recebam um e-mail de alerta sempre que seja publicitado no Citius* (*na página “publicidade dos processos especiais de revitalização, dos processos especiais para acordo de pagamento e dos processos de insolvência") um acto que envolva uma Entidade com um determinado NIF/NIPC.
                            <br />Os nossos Clientes escolhem um plano que lhes permite introduzir no sistema de pesquisa os NIF/NIPC que pretenderem.
                            <br />Quando o plano estiver activo, os nossos Clientes podem adicionar os NIF/NIPC que desejarem, consoante o plano escolhido.
                            <br />Desta forma, assim que for publicado no Citius* um acto que envolva um dos NIF/NIPC que constem na lista dos nossos Clientes, estes receberão um e-mail avisando-os que tal acto foi publicado.
                            <br />Por exemplo: Desta forma, os nossos Clientes ficarão informados de que uma Entidade da qual são credores foi declarada insolvente e poderão actuar em conformidade atempadamente.
                        </p>
                    </li>

                    <li>
                        <h3>2. O que é necessário para criar a minha conta?</h3>

                            <ul>
                                <li>Um endereço de e-mail válido, para envio de eventuais alertas e quaisquer outros contactos;</li>
                                <li>Indicar um número fiscal, para emissão da(s) factura(s)</li>
                            </ul>

                    </li>

                    <li>
                        <h3>3. Que informação me é enviada no e-mail de alerta?</h3>
                        <p>Receberá informação sobre o NIF/NIPC que foi detectado, a qualidade em que intervém no processo, em que Tribunal está o mesmo pendente, qual a sua espécie de e número, bem como o tipo de acto e a data da publicidade do mesmo no Citius*.</p>
                    </li>

                    <li>
                        <h3>4. Quantos planos existem?</h3>
                        <p>De momento, existem 3 planos:</p>
                            <ul>
                                <li>Individual: Permite adicionar até 50 NIF/NIPC</li>
                                <li>Profissional: Permite adicionar até 250 NIF/NIPC</li>
                                <li>(Em caso de necessidade de personalização de plano, <a target="_blank" href="mailto:geral@avisoslegais.pt">contacte-nos</a>.)</li>
                            </ul>

                    </li>

                    <li>
                        <h3>5. Quais os preços de cada plano?</h3>
                        <p></p>
                        <ul>
                                <li>Individual: 10€ / mês</li>
                                <li>Profissional: 25€ / mês</li>
                                <li>A estes valores acresce IVA à taxa legal em vigor</li>
                        </ul>
                    </li>

                    <li>
                        <h3>6. Posso experimentar gratuitamente?</h3>
                        <p>Sim. Oferecemos um período experimental de 30 dias no plano Profissional, ou seja a partir do momento em que se regista no site poderá adicionar até 250 NIF/NIPC e durante 30 dias será alertado gratuitamente de forma a validar o valor que o nosso serviço representa para si.

                    </li>


                    <li>
                        <h3>7. É mais barato se pagar anualmente?</h3>
                        <p>Sim, cada plano terá uma redução de 20% se for feito anualmente.</p>
                        <i>Exemplo: Se escolher pagar o plano Profissional anualmente, cada mês custará 20€ em vez de 25€.</i>

                    </li>

                    <li><h3>8. Como posso fazer o pagamento?</h3>
                    <p>
                    Se optar por uma modalidade de pagamentos mensal, pode pagar com cartão de crédito (VISA), sendo o valor descontado mensalmente.
                    <br/>No caso de optar por pagar anualmente, poderá fazê-lo através de uma referência multibanco, não sendo necessário cartão de crédito.
                    </p>
                    </li>
                    <li><h3>9. Posso alterar os NIF/NIPC no meu plano?</h3>
                    <p>Sim. Em qualquer momento.
                    <br/>Basta apagar o NIF/NIPC que já não deseja pesquisar e adicionar um em substituição.
                    </p>
                    </li>
                    <li><h3>10. Posso alterar o meu plano quando quiser?</h3>
                    <p>Sim. Em qualquer momento.
                    <br/>Independentemente de alterar para um plano mais barato ou mais caro, devolvemos-lhe sempre o valor não utilizado do plano anterior, na devida proporção.
                    <br/>Nesse caso, para ser efectuada a devolução, basta enviar um e-mail para geral@avisoslegais.pt .
                    </p>
                    </li>
                    <li><h3>11. O Avisos Legais é confidencial?</h3>
                    <p>Sim, a 100%.
                    <br/>Não divulgamos qualquer informação sobre os nossos Clientes, como tal, as Entidades cujos NIF/NIPC forem introduzidos no nosso sistema de pesquisa não conseguem saber que as pesquisas estão a ser efectuadas.
                    </p>
                    </li>
                    <li><h3>12. Posso apagar a minha conta quando quiser?</h3>
                    <p>Sim. Em qualquer momento.
                    <br/>Relativamente à sua conta e aos seus dados pessoais, respeitamos a política de privacidade e protecção de dados pessoais, pelo que não guardaremos (por mais tempo que o estritamente necessário) qualquer dado pessoal dos nossos Clientes.
                    <br/>Os dados pessoais que recolhemos através da correspondência com os Clientes destinam-se, exclusivamente, a ser tratados no âmbito das relações comerciais / contratuais e pré-contratuais estabelecidas. Asseguramos a protecção da privacidade de quaisquer dados pessoais dos Clientes, designadamente, pelo seu armazenamento no nosso sistema, protegendo-os contra perda, destruição, acesso não autorizado, alteração ou divulgação, em cumprimento do disposto no Regulamento Geral sobre a Protecção de Dados Pessoais (Regulamento nº 2016/679 de Parlamento Europeu e do Conselho de 27 de Abril de 2016) 
                    </p>
                    </li>
                    <li><h3>13. Quem é a Equipa do Avisos Legais?</h3>
                    <p>A Equipa é composta por um conjunto de Informáticos e Advogados que considera muito útil o desenvolvimento e implementação de uma solução informática simples, rápida e eficaz para auxiliar os credores antes e durante a existência de um PER ou Processo de Insolvência que envolva uma Entidade sua devedora.
                    <br/>A Plataforma AvisosLegais.pt e o serviço Avisos Legais são mantidos e geridos pela empresa informática de capital e origem portuguesa, “Untold Ventures, Lda.”.
                    </p>


                </ul>

            </div>
        </div>
    </div>

</div>
