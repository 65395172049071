<div class="main">
    <div class="container terms">
        <div class="row">

            <div class="col-md-12" >

                <div *ngIf="isLanding">
                    <a class="logo-1" routerLink="/"></a>
                </div>
                <br/><br />

                <h1>Termos & Condições para utilização do serviço</h1>
                <div style="text-align:justify">
                <p><span style="font-weight: 400;">A cria&ccedil;&atilde;o de uma conta e/ou a utiliza&ccedil;&atilde;o do servi&ccedil;o do&nbsp;</span><strong><em>Avisos Legais</em></strong><span style="font-weight: 400;"> e inerente registo no site www.avisoslegais.pt</span><strong>&nbsp;</strong><span style="font-weight: 400;">est&aacute; sujeita &agrave; aceita&ccedil;&atilde;o sem reservas por parte do Cliente/Utilizador dos presentes&nbsp;</span><em><span style="font-weight: 400;">Termos e Condi&ccedil;&otilde;es de Utiliza&ccedil;&atilde;o</span></em><span style="font-weight: 400;">&nbsp;(doravante &ldquo;TC&rdquo;) que se seguem, que podem ser revistos em qualquer altura, aplicando-se sempre a sua vers&atilde;o mais recente. Contudo, os novos TC&nbsp;s&oacute; se aplicar&atilde;o aos Clientes j&aacute; existentes quando a subscri&ccedil;&atilde;o do Servi&ccedil;o for renovada, dependendo do plano de pagamentos escolhido pelo Cliente.</span></p>
                <p>&nbsp;</p>
                <p><strong>I - Defini&ccedil;&otilde;es:</strong></p>
                <p><strong>Avisos Legais</strong><span style="font-weight: 400;"> &ndash; A denomina&ccedil;&atilde;o do Servi&ccedil;o/Produto disponibilizado ao Cliente atrav&eacute;s do site/plataforma www.avisoslegais.pt</span></p>
                <p><strong>Site</strong><span style="font-weight: 400;"> - www.avisoslegais.pt</span></p>
                <p><strong>Cliente/Utilizador</strong><span style="font-weight: 400;"> &ndash; A pessoa, singular ou colectiva, subscritora do Servi&ccedil;o, mediante o pagamento respectivo do mesmo</span></p>
                <p><strong>Empresa</strong><span style="font-weight: 400;"> &ndash; Untold Ventures, Lda., entidade gestora do Servi&ccedil;o</span></p>
                <p><strong>Autores</strong><span style="font-weight: 400;"> &ndash; O conjunto de pessoas que idealizou e desenvolve o Servi&ccedil;o</span></p>
                <p><strong>Citius</strong><span style="font-weight: 400;"> &ndash; A p&aacute;gina destinada &agrave; &ldquo;Publicidade dos processos especiais de revitaliza&ccedil;&atilde;o, dos processos especiais para acordo de pagamento e dos processos de insolv&ecirc;ncia&rdquo;, disponibilizada na internet atrav&eacute;s do endere&ccedil;o: http://www.citius.mj.pt/Site/consultas/ConsultasCire.aspx</span></p>
                <p><strong>Plano de subscri&ccedil;&atilde;o do Servi&ccedil;o </strong><span style="font-weight: 400;">&ndash; Per&iacute;odo pago pelo Cliente, atrav&eacute;s do qual o Servi&ccedil;o fica activo e dispon&iacute;vel para utiliza&ccedil;&atilde;o por parte do Cliente.</span></p>
                <p>&nbsp;</p>
                <p><strong>II &ndash; Disposi&ccedil;&otilde;es Gerais</strong></p>
                <ol>
                <li><span style="font-weight: 400;"> A&nbsp;</span><span style="font-weight: 400;">Empresa</span><span style="font-weight: 400;">, com sede na Av. Praia da Vit&oacute;ria, n&ordm; 11, 5&ordm;, em Lisboa, com o NIPC 514604778, registada na C. R. C. de Lisboa sob o mesmo n&ordm;, capital social de 400,00 Euros, concede a cada Cliente subscritor </span><span style="font-weight: 400;">o direito &agrave;</span><span style="font-weight: 400;"> utiliza&ccedil;&atilde;o&nbsp;tempor&aacute;ria e n&atilde;o exclusiva do servi&ccedil;o&nbsp;</span><strong><em>Avisos Legais </em></strong><span style="font-weight: 400;">(doravante&nbsp;o &ldquo;</span><strong>Servi&ccedil;o&rdquo;</strong><span style="font-weight: 400;">)&nbsp;de acordo com os presentes TC.</span></li>
                <li><span style="font-weight: 400;"> A&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;"> e os outros propriet&aacute;rios de componentes do&nbsp;</span><strong>Servi&ccedil;o</strong> <span style="font-weight: 400;">reservam para si o direito de os rever e alterar.</span></li>
                <li><span style="font-weight: 400;"> Os presentes TC s&atilde;o redigidos em l&iacute;ngua portuguesa, idioma que o&nbsp;Cliente, ao aceit&aacute;-los no momento de subscri&ccedil;&atilde;o do Servi&ccedil;o, confirma compreender suficientemente bem para estar plenamente consciente dos seus direitos e deveres.</span></li>
                <li><span style="font-weight: 400;"> A utiliza&ccedil;&atilde;o do Servi&ccedil;o e inerentes TC regem-se pela lei portuguesa. Se da utiliza&ccedil;&atilde;o do&nbsp;</span><strong>Produto&nbsp;</strong><span style="font-weight: 400;">resultarem quaisquer lit&iacute;gios que n&atilde;o possam ser dirimidos por m&uacute;tuo acordo, as Partes poder&atilde;o recorrer ao tribunal competente do foro da Comarca de Lisboa, com expressa ren&uacute;ncia a qualquer outro.</span></li>
                <li><span style="font-weight: 400;"> A eventual aceita&ccedil;&atilde;o, por parte da&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;">, da inexecu&ccedil;&atilde;o de qualquer cl&aacute;usula ou disposi&ccedil;&atilde;o destes TC, a qualquer momento, dever&aacute; ser interpretada como mera liberalidade, n&atilde;o constituindo uma nova&ccedil;&atilde;o e n&atilde;o implicando, portanto, desist&ecirc;ncia de exigir o cumprimento dessa cl&aacute;usula ou disposi&ccedil;&atilde;o ou do direito de judicialmente obrigar &agrave; sua execu&ccedil;&atilde;o.</span></li>
                <li><span style="font-weight: 400;"> A eventual nulidade ou anula&ccedil;&atilde;o parcial ou total de qualquer cl&aacute;usula destes TC n&atilde;o determina, s&oacute; por si, a invalidade da totalidade dos mesmos, excepto se, em resultado disso, os direitos ou deveres das partes forem decisivamente alterados ou violados.</span></li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>III&nbsp;&ndash; Termos e Condi&ccedil;&otilde;es Espec&iacute;ficos</strong></p>
                <ol start="7">
                <li><span style="font-weight: 400;"> O&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> &eacute; composto por um&nbsp;</span><em><span style="font-weight: 400;">software</span></em><span style="font-weight: 400;"> de consulta, atrav&eacute;s de um sistema de pesquisa de elementos relativos &agrave; publicidade de an&uacute;ncios em processos especiais de revitaliza&ccedil;&atilde;o e/ou processos de insolv&ecirc;ncia de pessoas singulares e colectivas, sob a forma de bases de dados adequadas ao sistema de pesquisa.</span></li>
                <li><span style="font-weight: 400;"> Os direitos de autor sobre o&nbsp;Servi&ccedil;o&nbsp;e seus componentes, bem como a marca Avisos Legais</span><span style="font-weight: 400;">,</span><span style="font-weight: 400;"> est&atilde;o registados e s&atilde;o protegidos pela Lei, nomeadamente, pelo C&oacute;digo do Direito de Autor e dos Direitos Conexos e demais legisla&ccedil;&atilde;o aplic&aacute;vel, bem como por tratados internacionais.</span></li>
                <li><span style="font-weight: 400;"> O&nbsp;</span><strong>Cliente</strong><span style="font-weight: 400;">, ao subscrever o</span><strong> Servi&ccedil;o</strong><span style="font-weight: 400;">,&nbsp;fica titular do direito de utiliza&ccedil;&atilde;o do&nbsp;mesmo, durante o per&iacute;odo contratado, que funciona de acordo com os seguintes passos:</span></li>
                </ol>
                <p><span style="font-weight: 400;">1&ordm; Para criar a sua conta, o Cliente ter&aacute; de indicar:</span></p>
                <ol>
                <li><span style="font-weight: 400;">a) aceitar expressamente e sem reservas os presentes Termos e Condi&ccedil;&otilde;es, atrav&eacute;s de coloca&ccedil;&atilde;o de </span><span style="font-weight: 400;">☑</span><span style="font-weight: 400;"> no formul&aacute;rio indicado para o efeito</span></li>
                <li><span style="font-weight: 400;">b) um endere&ccedil;o de e-mail v&aacute;lido, para envio de eventuais alertas e quaisquer outros contactos</span></li>
                <li><span style="font-weight: 400;">c) escolher um nome de Utilizador </span></li>
                <li><span style="font-weight: 400;">d) indicar um n&uacute;mero fiscal, para emiss&atilde;o da(s) factura(s)</span></li>
                </ol>
                <p><span style="font-weight: 400;">2&ordm; Ap&oacute;s criar a sua conta, o Cliente escolhe um plano que lhe permite introduzir no sistema de pesquisa os NIF/NIPC que pretender;</span></p>
                <p><span style="font-weight: 400;">3&ordm; Ap&oacute;s o pagamento do plano definido, este fica activo e o Cliente pode adicionar os NIF/NIPC que desejar, consoante o plano escolhido;</span></p>
                <p><span style="font-weight: 400;">4&ordm; O Cliente recebe um e-mail de alerta sempre que seja publicitado </span><span style="font-weight: 400;">via </span><span style="font-weight: 400;">Citius* </span><span style="font-weight: 400;">(*na p&aacute;gina &ldquo;publicidade dos processos especiais de revitaliza&ccedil;&atilde;o, dos processos especiais para acordo de pagamento e dos processos de insolv&ecirc;ncia)</span><span style="font-weight: 400;"> um acto que envolva uma Empresa com um determinado NIF/NIPC.</span>
                <br />Para garantir que os e-mails enviados pelo Avisos Legais são devidamente recebidos na caixa de entrada de e-mail do Cliente, este deverá tomar as medidas adequadas nesse sentido, tais como, por exemplo (dependendo da configuração da respectiva caixa de entrada), adicionar os seguintes e-mails à sua lista de contactos: geral@avisoslegais.pt  e  alertas@avisoslegais.pt</p>
                <p><span style="font-weight: 400;">5&ordm; De momento, existem 3 planos, j&aacute; com IVA inclu&iacute;do: </span></p>
                <p><span style="font-weight: 400;">Individual: Permite adicionar at&eacute; 50 NIF/NIPC, custa 10&euro; / m&ecirc;s</span></p>
                <p><span style="font-weight: 400;">Profissional: Permite adicionar at&eacute; 250 NIF/NIPC, custa 25&euro; / m&ecirc;s</span></p>
                <p>&nbsp;</p>
                <p><span style="font-weight: 400;">6&ordm; </span><span style="font-weight: 400;">O pagamento anual antecipado confere um desconto de 20% sob o valor do plano contratado. </span></p>
                <p><span style="font-weight: 400;">7&ordm; O pagamento pode ser feito com cart&atilde;o de cr&eacute;dito (VISA), sendo o valor descontado mensalmente, se optar por essa modalidade de pagamentos. No caso de optar por pagar anualmente, poder&aacute; faz&ecirc;-lo </span><span style="font-weight: 400;">tamb&eacute;m </span><span style="font-weight: 400;">atrav&eacute;s de uma refer&ecirc;ncia multibanco, n&atilde;o sendo necess&aacute;rio cart&atilde;o de cr&eacute;dito.</span></p>
                <p><span style="font-weight: 400;">8&ordm; Em qualquer momento, o Cliente pode alterar os NIF/NIPC do seu plano, bastando para tal apagar o NIF/NIPC que j&aacute; n&atilde;o deseja pesquisar e adicionar um em substitui&ccedil;&atilde;o. </span></p>
                <p><span style="font-weight: 400;">9&ordm; Em qualquer momento, o Cliente pode alterar o seu plano. Independentemente de alterar para um plano mais barato ou mais caro, ser-lhe-&aacute; devolvido sempre o valor n&atilde;o utilizado do plano anterior, na devida propor&ccedil;&atilde;o. Nesse caso, para ser efectuada a devolu&ccedil;&atilde;o, basta enviar um e-mail para planos@avisoslegais.pt .</span></p>
                <p><span style="font-weight: 400;">10&ordm; O Avisos Legais &eacute; totalmente confidencial, pois n&atilde;o &eacute; divulgada qualquer informa&ccedil;&atilde;o sobre o Cliente. Desta forma, as Entidades cujos NIF/NIPC forem introduzidos no sistema de pesquisa n&atilde;o conseguem saber que as pesquisas est&atilde;o a ser efectuadas.</span></p>
                <p>&nbsp;</p>
                <ol start="10">
                <li><span style="font-weight: 400;"> O&nbsp;</span><strong>Cliente</strong><span style="font-weight: 400;">, ou quem o represente, obriga-se a comunicar a todos os utilizadores do Servi&ccedil;o subscrito os presentes TC.</span></li>
                <li><span style="font-weight: 400;"> O&nbsp;</span><strong>Cliente</strong><span style="font-weight: 400;"> obriga-se a n&atilde;o realizar e/ou tentar a engenharia inversa do </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, a descompila&ccedil;&atilde;o, decomposi&ccedil;&atilde;o ou altera&ccedil;&atilde;o do&nbsp;</span><em><span style="font-weight: 400;">software</span></em><span style="font-weight: 400;"> do mesmo, a altera&ccedil;&atilde;o do conte&uacute;do dos ficheiros de dados ou a extrac&ccedil;&atilde;o de informa&ccedil;&atilde;o a partir dos ficheiros de dados.</span></li>
                <li><span style="font-weight: 400;"> O&nbsp;</span><strong>Cliente</strong><span style="font-weight: 400;"> assume a responsabilidade:</span>
                    <ul>
                        <li><p><strong>12.1</strong><span style="font-weight: 400;"> Pela decis&atilde;o de utilizar ou n&atilde;o o&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, depois de avaliar a sua adequabilidade &agrave; finalidade pretendida, os riscos inerentes &agrave; utiliza&ccedil;&atilde;o de sistemas inovadores e a sensibilidade do meio em que a sua utiliza&ccedil;&atilde;o se insere;</span></p></li>
                        <li><p><strong>12.2</strong><span style="font-weight: 400;"> Pela eventual utiliza&ccedil;&atilde;o indevida do&nbsp;</span><strong>Servi&ccedil;o,</strong><span style="font-weight: 400;">&nbsp;designadamente utiliza&ccedil;&atilde;o contr&aacute;ria &agrave;s instru&ccedil;&otilde;es de utiliza&ccedil;&atilde;o ou m&eacute;todos de opera&ccedil;&atilde;o, ou utiliza&ccedil;&atilde;o contr&aacute;ria &agrave; lei;</span></p></li>
                        <li><p><strong>12.3</strong><span style="font-weight: 400;"> Por assegurar a forma&ccedil;&atilde;o e informa&ccedil;&atilde;o dos Utilizadores relativamente &agrave; forma correcta de utilizar o&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, limita&ccedil;&otilde;es e boas pr&aacute;ticas.</span></p></li>
                        <li><p><strong>12.4 </strong><strong>Por u</strong><span style="font-weight: 400;">tilizar o Servi&ccedil;o de boa-f&eacute; e em cumprimento da lei e das normas de utiliza&ccedil;&atilde;o que lhe sejam transmitidas pela Empresa, sendo-lhe vedado o seu uso para fins il&iacute;citos ou que prejudiquem a Empresa, ou o normal funcionamento desta ou do grupo econ&oacute;mico a que eventualmente perten&ccedil;a.</span></p></li>
                    </ul>
                </li>
                </ol>

                <ol start="13">
                <li><span style="font-weight: 400;"> O&nbsp;</span><strong>Cliente</strong><span style="font-weight: 400;">, ao utilizar o&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, est&aacute; consciente de que este, apesar do n&iacute;vel de qualidade e de cuidado utilizado no seu desenvolvimento:</span>
                    <ul>
                        <li><p><strong>13.1</strong><span style="font-weight: 400;"> Tem ou pode ter limita&ccedil;&otilde;es e erros, conhecidos ou n&atilde;o, i) decorrentes do</span><em><span style="font-weight: 400;">&nbsp;estado da arte</span></em><span style="font-weight: 400;">&nbsp;das tecnologias e do conhecimento, e; ii) dos recursos (da&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;">, dos&nbsp;</span><strong>Autores</strong><span style="font-weight: 400;">, do&nbsp;</span><strong>Cliente</strong><span style="font-weight: 400;">&nbsp;ou de terceiros) utilizados no seu desenvolvimento e/ou opera&ccedil;&atilde;o que podem conduzir a erros no &acirc;mbito da sua opera&ccedil;&atilde;o;</span></p></li>
                        <li><p><strong>13.2</strong><span style="font-weight: 400;"> &nbsp;Pode, em caso fortuito ou de for&ccedil;a maior, ser adulterado, originando erros imprevis&iacute;veis, nomeadamente, (i) erros nos conte&uacute;dos publicitados (ii), v&iacute;rus e demais elementos nocivos no Site www.avisoslegais.pt ou no servidor que o abastece (iii), vulnerabilidade do Site e/ou acesso &agrave;s medidas de seguran&ccedil;a que se adoptem no mesmo (iv), danos ou preju&iacute;zos causados ao </span><strong>Utilizador</strong><span style="font-weight: 400;"> ou a terceiros por qualquer pessoa que aceda aos dados atrav&eacute;s da viola&ccedil;&atilde;o dos sistemas de seguran&ccedil;a do Site; </span></p></li>
                    <li></ul>
                </li>
                </ol>


                <ol start="14">
                <li><span style="font-weight: 400;"> Tanto a Empresa como o Cliente reconhecem expressamente que o </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> depende directamente da informa&ccedil;&atilde;o publicada e publicitada no Site Citius*, nomeadamente, da informa&ccedil;&atilde;o publicada e publicitada na p&aacute;gina destinada &agrave; &ldquo;</span><em><span style="font-weight: 400;">Publicidade dos processos especiais de revitaliza&ccedil;&atilde;o, dos processos especiais para acordo de pagamento e dos processos de insolv&ecirc;ncia&rdquo;</span></em><span style="font-weight: 400;">, disponibilizada na internet atrav&eacute;s do endere&ccedil;o:</span></li>
                </ol>
                <p><span style="font-weight: 400;">http://www.citius.mj.pt/Site/consultas/ConsultasCire.aspx</span></p>
                <ol start="15">
                <li><span style="font-weight: 400;"> O Cliente desde j&aacute; reconhece e aceita expressamente que a utiliza&ccedil;&atilde;o do </span><strong>Servi&ccedil;o Avisos Legais</strong><span style="font-weight: 400;"> disponibilizado em </span><strong>www.avisoslegais.pt</strong><span style="font-weight: 400;"> n&atilde;o dispensa a consulta directa e independente por parte do Cliente do endere&ccedil;o referido no n&ordm; anterior e/ou de quaisquer outros endere&ccedil;os e/ou informa&ccedil;&otilde;es prestadas publicamente pelas Entidades e Institui&ccedil;&otilde;es competentes para o efeito, no &acirc;mbito da actividade e &aacute;reas de actua&ccedil;&atilde;o em causa.</span></li>
                <li><span style="font-weight: 400;"> A utiliza&ccedil;&atilde;o do Servi&ccedil;o n&atilde;o permite:</span>
                    <ul>
                        <li><p><strong>16.1.</strong><span style="font-weight: 400;"> A comercializa&ccedil;&atilde;o no todo ou em parte da informa&ccedil;&atilde;o obtida e prestada pelo&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, mesmo que alterada ou adaptada.</span></p></li>
                        <li><p><strong>16.2.</strong><span style="font-weight: 400;"> Criar e ou explorar um outro servi&ccedil;o ou produto, assente na utiliza&ccedil;&atilde;o directa ou indirecta do&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, sem pr&eacute;via autoriza&ccedil;&atilde;o escrita da&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;">.</span></p></li>
                        <li><p><strong>16.3</strong><span style="font-weight: 400;"> Aceder &agrave; informa&ccedil;&atilde;o dispon&iacute;vel em&nbsp;</span><em><span style="font-weight: 400;">sites</span></em><span style="font-weight: 400;">&nbsp;externos ao&nbsp;</span><strong><em>Avisos Legais</em></strong><span style="font-weight: 400;">, mesmo que o acesso a essa informa&ccedil;&atilde;o seja efectuado atrav&eacute;s de hiperliga&ccedil;&otilde;es presentes em&nbsp;documentos constantes no site do </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">.&nbsp;As&nbsp;condi&ccedil;&otilde;es de acesso&nbsp;a essa informa&ccedil;&atilde;o s&atilde;o estipuladas pelas entidades respons&aacute;veis por cada um dos&nbsp;</span><em><span style="font-weight: 400;">sites</span></em><span style="font-weight: 400;">&nbsp;e/ou pelas normas legais que definem o enquadramento legal subjacente &agrave; disponibiliza&ccedil;&atilde;o de cada tipo de informa&ccedil;&atilde;o.</span></p></li>
                    </ul>
                </li>
                </ol>

                <ol start="17">
                <li><span style="font-weight: 400;">Ao efectuar o pagamento de um plano de subscri&ccedil;&atilde;o do </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, o Cliente reconhece e autoriza que a Empresa recolha as informa&ccedil;&otilde;es necess&aacute;rias sobre a compra ou transac&ccedil;&atilde;o, incluindo as informa&ccedil;&otilde;es de pagamento, como o n&uacute;mero do cart&atilde;o de cr&eacute;dito ou d&eacute;bito e outras informa&ccedil;&otilde;es do cart&atilde;o, bem como outras informa&ccedil;&otilde;es de conta e de autentica&ccedil;&atilde;o, detalhes de factura&ccedil;&atilde;o, envio e contacto.</span></li>
                <li><span style="font-weight: 400;">A contrata&ccedil;&atilde;o do </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> obriga a um registo pr&eacute;vio pelo </span><strong>Cliente</strong><span style="font-weight: 400;">, nos termos do qual este se obriga a informar com verdade e a garantir a autenticidade de todos os dados pessoais que introduza.</span>
                    <ul>
                        <li><p><strong>18.1</strong><span style="font-weight: 400;"> Uma vez registado, o Utilizador Registado poder&aacute; aceder&aacute; &agrave; &ldquo;&Aacute;rea de Cliente&rdquo;, para o que dever&aacute; inserir o seu </span><span style="font-weight: 400;">&ldquo;Nome de Utilizador&rdquo;</span><span style="font-weight: 400;"> e uma &ldquo;Password&rdquo;; </span></p></li>
                        <li><p><strong>18.2</strong><span style="font-weight: 400;"> A Empresa n&atilde;o se responsabiliza pela veracidade das informa&ccedil;&otilde;es fornecidas pelo </span><strong>Cliente</strong><span style="font-weight: 400;">. Apenas este responder&aacute;, em qualquer caso, pela veracidade, exactid&atilde;o e autenticidade dos dados pessoais por si introduzidos. </span></p></li>
                        <li><p><strong>18.3</strong><span style="font-weight: 400;"> Uma vez atribu&iacute;do um Identificador de Acesso e/ou Password ao </span><strong>Cliente</strong><span style="font-weight: 400;">, este reconhece o seu car&aacute;cter intransmiss&iacute;vel e obriga-se a utilizar essa informa&ccedil;&atilde;o de forma l&iacute;cita e diligente, mantendo-a em sigilo, sendo respons&aacute;vel pela sua guarda e confidencialidade, comprometendo-se a n&atilde;o ceder o seu uso a terceiros, tempor&aacute;ria ou definitivamente, nem a permitir o seu acesso a terceiros. </span></p></li>
                        <li><p><strong>18.4</strong><span style="font-weight: 400;"> O </span><strong>Cliente</strong><span style="font-weight: 400;"> obriga-se a informar a </span><strong>Empresa</strong><span style="font-weight: 400;"> de imediato, de qualquer facto ou acto que permita a utiliza&ccedil;&atilde;o indevida dos identificadores ou c&oacute;digos por terceiros, tais como furto, roubo, extravio ou acesso n&atilde;o autorizado aos mesmos, com o fim de proceder-se &agrave; sua anula&ccedil;&atilde;o e/ou substitui&ccedil;&atilde;o. </span></p></li>
                        <li><p><strong>18.5</strong><span style="font-weight: 400;"> Sempre que o </span><strong>Utilizador</strong><span style="font-weight: 400;"> n&atilde;o comunique a ocorr&ecirc;ncia de tais factos, a Empresa fica exonerada de qualquer responsabilidade que possa derivar da utiliza&ccedil;&atilde;o indevida dos identificadores ou c&oacute;digos por parte de terceiros n&atilde;o autorizados. </span></p></li>
                        <li><p><strong>18.6</strong><span style="font-weight: 400;"> Em qualquer caso, o acesso, navega&ccedil;&atilde;o e utiliza&ccedil;&atilde;o da p&aacute;gina Web e a utiliza&ccedil;&atilde;o ou contrata&ccedil;&atilde;o dos servi&ccedil;os ou produtos que sejam oferecidos atrav&eacute;s do mesmo &eacute; da &uacute;nica e exclusiva responsabilidade do </span><strong>Utilizador</strong><span style="font-weight: 400;">, comprometendo-se este a observar de forma fiel e diligente qualquer instru&ccedil;&atilde;o adicional fornecida pela Empresa ou por pessoal autorizado da mesma, relacionada com a utiliza&ccedil;&atilde;o do </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> e respectivos conte&uacute;dos. </span></p></li>
                    </ul>
                </li>
                </ol>
                <ol start="19">
                <li><span style="font-weight: 400;"> A </span><strong>Empresa</strong><span style="font-weight: 400;"> (e/ou os </span><strong>Autores)</strong><span style="font-weight: 400;"> &eacute; titular da licen&ccedil;a dos direitos de explora&ccedil;&atilde;o em mat&eacute;ria de propriedade intelectual, industrial e de imagem sobre os conte&uacute;dos dispon&iacute;veis atrav&eacute;s do </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, nomeadamente, sobre os textos, desenhos gr&aacute;ficos, desenhos, c&oacute;digos, software, fotografias, v&iacute;deos, sons, bases de dados, &iacute;ndices, imagens, marcas, log&oacute;tipos, express&otilde;es e informa&ccedil;&otilde;es e, em geral, qualquer outra cria&ccedil;&atilde;o protegida pelas normas nacionais e tratados internacionais sobre a propriedade intelectual, de ora em diante designados por Conte&uacute;dos. </span>
                    <ul>
                        <li><p><strong>19.1</strong><span style="font-weight: 400;"> A </span><strong>Empresa</strong><span style="font-weight: 400;"> reserva a seu favor todos os direitos de propriedade intelectual e industrial sobre os Conte&uacute;dos e </span><span style="font-weight: 400;">resultantes da evolu&ccedil;&atilde;o dos mesmo e</span><span style="font-weight: 400;">, em particular, &eacute; proibido modificar, copiar, reproduzir sob forma alguma ou por quaisquer meios - electr&oacute;nicos, mec&acirc;nicos, ou outros, incluindo fotoc&oacute;pia de p&aacute;ginas impressas, grava&ccedil;&otilde;es, ou por qualquer processo de armazenamento de informa&ccedil;&atilde;o -, comunicar publicamente, transformar ou distribuir, por qualquer forma, a totalidade ou parte dos Conte&uacute;dos inseridos no </span><strong>Site www.avisoslegais.pt</strong><span style="font-weight: 400;">, a menos que a </span><strong>Empresa</strong><span style="font-weight: 400;"> o autorize expressamente e por escrito. </span></p></li>
                        <li><p><strong>19.2</strong><span style="font-weight: 400;"> O acesso e navega&ccedil;&atilde;o do </span><strong>Utilizador</strong><span style="font-weight: 400;"> pelo Site em nenhum caso poder&aacute; ser entendido como ren&uacute;ncia, transmiss&atilde;o, concess&atilde;o de licen&ccedil;a ou cess&atilde;o total ou parcial dos direitos supra referidos por parte da Empresa. </span></p></li>
                        <li><p><strong>19.3</strong><span style="font-weight: 400;"> As refer&ecirc;ncias a nomes e marcas comerciais ou registadas, log&oacute;tipos ou outros s&iacute;mbolos distintivos, sejam da titularidade da Empresa ou de terceiras empresas, n&atilde;o podem ser utilizadas sem o consentimento expresso da Empresa ou dos seus leg&iacute;timos propriet&aacute;rios. Em nenhum caso, excepto se consentido expressa e formalmente, se confere ao Utilizador qualquer direito sobre as marcas, log&oacute;tipos e/ou s&iacute;mbolos distintivos inclu&iacute;dos no </span><strong>Site</strong><span style="font-weight: 400;"> e atrav&eacute;s do </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> e protegidos pela legisla&ccedil;&atilde;o aplic&aacute;vel.</span></p></li>
                        <li><p><strong>19.4</strong><span style="font-weight: 400;"> A </span><strong>Empresa</strong><span style="font-weight: 400;"> pode proporcionar conex&otilde;es ou liga&ccedil;&otilde;es, directa ou indirectamente, a recursos ou p&aacute;ginas web de Internet que se encontrem fora do </span><strong>Site</strong><span style="font-weight: 400;">, para fins informativos. Tais conex&otilde;es n&atilde;o constituem, naturalmente, qualquer convite &agrave; contrata&ccedil;&atilde;o de produtos e/ou servi&ccedil;os que estejam dispon&iacute;veis nas p&aacute;ginas web de destino, nem implica a exist&ecirc;ncia de v&iacute;nculo ou rela&ccedil;&atilde;o comercial ou de depend&ecirc;ncia com a empresa titular da p&aacute;gina web conectada, pelo que a Empresa n&atilde;o ser&aacute;, em circunst&acirc;ncia alguma, respons&aacute;vel pelo estabelecimento das condi&ccedil;&otilde;es gerais e particulares de utiliza&ccedil;&atilde;o, presta&ccedil;&atilde;o ou contrata&ccedil;&atilde;o destes servi&ccedil;os por terceiros, n&atilde;o sendo respons&aacute;vel, </span><span style="font-weight: 400;">em caso algum</span><span style="font-weight: 400;">, pelos mesmos. </span></p></li>
                        <li><p><strong>19.5</strong><span style="font-weight: 400;"> A </span><strong>Empresa</strong><span style="font-weight: 400;"> n&atilde;o tem a faculdade, nem os meios humanos e/ou t&eacute;cnicos, para conhecer, controlar e aprovar toda a informa&ccedil;&atilde;o, conte&uacute;dos, produtos ou servi&ccedil;os proporcionados por outras p&aacute;ginas web com as quais possa estabelecer liga&ccedil;&otilde;es desde o </span><strong>Site</strong><span style="font-weight: 400;">, pelo que</span><span style="font-weight: 400;">,</span><span style="font-weight: 400;"> n&atilde;o responder&aacute; por qualquer aspecto relativo &agrave;s p&aacute;ginas web de terceiros, nomeadamente, sobre o seu funcionamento, acesso, dados, informa&ccedil;&atilde;o, arquivos, qualidade e fiabilidade dos seus produtos e servi&ccedil;os, bem como sobre as suas pr&oacute;prias liga&ccedil;&otilde;es e/ou qualquer dos seus conte&uacute;dos em geral.</span></p></li>
                        <li><p><strong>19.6</strong><span style="font-weight: 400;"> Os </span><strong>Utilizadores</strong><span style="font-weight: 400;">, outras entidades ou p&aacute;ginas web que desejem estabelecer algum tipo de conex&atilde;o ou liga&ccedil;&atilde;o com o </span><strong>Site</strong><span style="font-weight: 400;"> dever&atilde;o obter a autoriza&ccedil;&atilde;o pr&eacute;via, expressa e por escrito da </span><strong>Empresa</strong><span style="font-weight: 400;">. </span></p></li>
                        <li><p><strong>19.6.1</strong><span style="font-weight: 400;"> A liga&ccedil;&atilde;o somente poder&aacute; dirigir-se &agrave; p&aacute;gina principal do Site, excepto se a </span><strong>Empresa</strong><span style="font-weight: 400;"> autorizar algo diferente, expressamente. </span></p></li>
                        <li><p><strong>19.6.2</strong><span style="font-weight: 400;"> A liga&ccedil;&atilde;o deve ser absoluta e completa, ou seja, deve conduzir o </span><strong>Utilizador</strong><span style="font-weight: 400;">, mediante um "clic", &agrave; p&aacute;gina principal do Site e abranger completamente toda a extens&atilde;o do ecr&atilde; dessa p&aacute;gina principal. Em nenhum caso, excepto se a </span><strong>Empresa</strong><span style="font-weight: 400;"> autorizar de forma diferente, expressamente, a p&aacute;gina web a partir da qual se efectua a liga&ccedil;&atilde;o poder&aacute; reproduzir, por qualquer forma ou meio, o </span><strong>Site</strong><span style="font-weight: 400;">, ou incluir o </span><strong>Site</strong><span style="font-weight: 400;"> como parte da sua p&aacute;gina web ou dentro de um dos seus "frames", ou criar um "browser" sobre alguma das p&aacute;ginas do </span><strong>Site</strong><span style="font-weight: 400;">. </span></p></li>
                        <li><p><strong>19.6.3</strong><span style="font-weight: 400;"> Na p&aacute;gina web a partir da qual se estabelece a liga&ccedil;&atilde;o n&atilde;o se poder&aacute; declarar, sob qualquer forma, que a </span><strong>Empresa </strong><span style="font-weight: 400;">autorizou tal liga&ccedil;&atilde;o, excepto se tal se verificou. Caso a empresa que efectuou a liga&ccedil;&atilde;o a partir da sua p&aacute;gina web ao </span><strong>Site</strong><span style="font-weight: 400;">, de forma correcta, deseje incluir em tal p&aacute;gina a marca, denomina&ccedil;&atilde;o, nome comercial, r&oacute;tulo, log&oacute;tipo, ou qualquer outro s&iacute;mbolo identificativo da </span><strong>Empresa</strong><span style="font-weight: 400;"> e/ou do </span><strong>Site</strong><span style="font-weight: 400;">, dever&aacute; obter a autoriza&ccedil;&atilde;o pr&eacute;via, expressa e por escrito da </span><strong>Empresa</strong><span style="font-weight: 400;">. </span></p></li>
                        <li><p><strong>19.6.4</strong><span style="font-weight: 400;"> Est&atilde;o expressamente proibidas as conex&otilde;es ao </span><strong>Site</strong><span style="font-weight: 400;"> a partir de todas as p&aacute;ginas web que contenham mat&eacute;rias, informa&ccedil;&atilde;o ou conte&uacute;dos il&iacute;citos, ilegais, degradantes, obscenos e, em geral, que ofendam a moral e os bons costumes, a ordem p&uacute;blica, a legisla&ccedil;&atilde;o vigente, as normas sociais geralmente aceites ou que sejam nocivos para os direitos leg&iacute;timos de terceiros.</span></p></li>
                    </ul>
                </li>
                </ol>
                


                <ol start="20">
                <li><span style="font-weight: 400;"> A </span><strong>Empresa</strong><span style="font-weight: 400;"> utiliza cookies para facilitar aos </span><strong>Utilizadores</strong><span style="font-weight: 400;"> a navega&ccedil;&atilde;o na sua p&aacute;gina web. </span>
                    <ul>
                        <li><p><strong>20.2</strong><span style="font-weight: 400;"> As cookies utilizadas n&atilde;o armazenam quaisquer dados pessoais, bem como qualquer informa&ccedil;&atilde;o que possa identificar o </span><strong>Utilizador</strong><span style="font-weight: 400;">. No caso de n&atilde;o querer receber as cookies, deve configurar o seu navegador de Internet para exclu&iacute;-las do disco r&iacute;gido do seu computador, bloque&aacute;-las ou alert&aacute;-lo em caso de instala&ccedil;&atilde;o das mesmas. </span></p></li>
                        <li><p><strong>20.1</strong><span style="font-weight: 400;"> As cookies s&atilde;o pequenos ficheiros de informa&ccedil;&atilde;o que permitem &agrave; </span><strong>Empresa</strong><span style="font-weight: 400;"> comparar e perceber como &eacute; feita a navega&ccedil;&atilde;o pelos </span><strong>Utilizadores</strong><span style="font-weight: 400;"> atrav&eacute;s da sua p&aacute;gina web e, consequentemente, poder melhorar o processo de navega&ccedil;&atilde;o;</span></p></li>
                        <li><p><strong>20.3</strong><span style="font-weight: 400;"> Para navegar de acordo com a configura&ccedil;&atilde;o de cookies pr&eacute;-programada pela </span><strong>Empresa</strong><span style="font-weight: 400;"> o </span><strong>Utilizador</strong><span style="font-weight: 400;"> apenas ter&aacute; de continuar a sua navega&ccedil;&atilde;o na p&aacute;gina Web e aceitar a Pol&iacute;tica de Cookies quando tal lhe seja solicitado. </span></p></li>
                    </ul>
                </li>
                </ol>



                <ol start="21">
                <li><span style="font-weight: 400;"> A </span><strong>Empresa</strong><span style="font-weight: 400;"> est&aacute; em condi&ccedil;&otilde;es de garantir ao Utilizador que forne&ccedil;a os seus dados pessoais, que a recolha e o tratamento dos dados pessoais ser&aacute; realizada em conformidade com o disposto na legisla&ccedil;&atilde;o aplic&aacute;vel, decorrendo para a </span><strong>Empresa</strong><span style="font-weight: 400;"> uma obriga&ccedil;&atilde;o de confidencialidade e sigilo. </span>
                    <ul>
                        <li><p><strong>21.1</strong><span style="font-weight: 400;"> Durante o processo de registo como </span><strong>Utilizador</strong><span style="font-weight: 400;">, o titular dos dados ser&aacute; informado da necessidade de fornecer os seus dados pessoais. Quando faculte uma direc&ccedil;&atilde;o de correio electr&oacute;nico ou de outro meio de comunica&ccedil;&atilde;o electr&oacute;nico, o </span><strong>Utilizador</strong><span style="font-weight: 400;">, desde logo, autoriza expressamente a </span><strong>Empresa</strong><span style="font-weight: 400;"> a utilizar o referido endere&ccedil;o como meio de comunica&ccedil;&atilde;o para, designadamente, responder ao seu pedido e/ou consulta, transmitir-lhe informa&ccedil;&atilde;o de natureza comercial sobre a </span><strong>Empresa</strong><span style="font-weight: 400;"> e inform&aacute;-lo sobre qualquer altera&ccedil;&atilde;o relevante que realize na sua P&aacute;gina Web, entre outras finalidades n&atilde;o especificadas. </span></p></li>
                        <li><p><strong>21.2</strong><span style="font-weight: 400;"> O </span><strong>Utilizador</strong><span style="font-weight: 400;"> poder&aacute; sempre, e a todo o momento, exercer o seu direito de acesso, rectifica&ccedil;&atilde;o, cancelamento e oposi&ccedil;&atilde;o, os quais poder&aacute; exercer mediante o envio de uma comunica&ccedil;&atilde;o escrita para o seguinte endere&ccedil;o de correio electr&oacute;nico: geral@alertaslegais.pt .</span></p></li>
                        <li><p><strong>21.3</strong><span style="font-weight: 400;"> Para os efeitos do disposto na referida Lei N.&ordm; 67/98, caso o </span><strong>Utilizador</strong><span style="font-weight: 400;"> venha a facultar dados de car&aacute;cter pessoal de terceiros dever&aacute; informar os visados acerca dos conte&uacute;dos dos dados facilitados, da finalidade do ficheiro e da possibilidade de exercer os direitos de acesso, rectifica&ccedil;&atilde;o, cancelamento e oposi&ccedil;&atilde;o, bem como dos dados identificativos e de contacto da </span><strong>Empresa</strong><span style="font-weight: 400;">. </span></p></li>
                        <li><p><strong>21.4</strong><span style="font-weight: 400;"> Os dados pessoais que recolhemos através da correspondência com V. Exa. destinam-se, exclusivamente, a ser tratados no âmbito das relações comerciais / contratuais e pré-contratuais estabelecidas. Asseguramos a protecção da privacidade dos seus dados pessoais, designadamente, pelo seu armazenamento no nosso sistema, protegendo-os contra perda, destruição, acesso não autorizado, alteração ou divulgação, em cumprimento do disposto no Regulamento Geral sobre a Protecção de Dados Pessoais (Regulamento nº 2016/679 de Parlamento Europeu e do Conselho de 27 de Abril de 2016)</span></li>
                    </ul>
                </li>
                </ol>

                <ol start="22">
                <li><span style="font-weight: 400;"> A </span><strong>Empresa</strong><span style="font-weight: 400;"> reserva-se o direito de, a qualquer momento e sem necessidade de pr&eacute;-aviso, modificar ou eliminar o conte&uacute;do, estrutura, aspecto gr&aacute;fico, servi&ccedil;os e condi&ccedil;&otilde;es de acesso e/ou utiliza&ccedil;&atilde;o, ou retirar, suspender ou interromper o acesso do Utilizador ao referido Site, sem que tal confira ao Utilizador o direito a qualquer indemniza&ccedil;&atilde;o e/ou compensa&ccedil;&atilde;o. </span>
                    <ul>
                        <li><p><strong>22.1</strong><span style="font-weight: 400;"> Ap&oacute;s a extin&ccedil;&atilde;o do acesso ao Site, seja por que causa for, continuar&atilde;o vigentes as proibi&ccedil;&otilde;es de uso de conte&uacute;dos, expostas anteriormente nos presentes TC. </span></p></li>
                        <li><p><strong>22.2</strong><span style="font-weight: 400;"> A </span><strong>Empresa</strong><span style="font-weight: 400;"> reserva-se, desde j&aacute;, o direito de modificar os presentes TC e/ou as condi&ccedil;&otilde;es particulares que, neste caso, tenham sido estabelecidas para a utiliza&ccedil;&atilde;o e/ou contrata&ccedil;&atilde;o dos produtos ou servi&ccedil;os prestados atrav&eacute;s do </span><strong>Site</strong><span style="font-weight: 400;">, quando considere oportuno, nomeadamente, em caso de necessidade de adequa&ccedil;&atilde;o a modifica&ccedil;&otilde;es legislativas e tecnol&oacute;gicas, sendo v&aacute;lidas e tendo efeitos a partir da sua publica&ccedil;&atilde;o no </span><strong>Site</strong><span style="font-weight: 400;">. </span></p></li>
                        <li><p><strong>22.3</strong><span style="font-weight: 400;"> O per&iacute;odo de vig&ecirc;ncia destas condi&ccedil;&otilde;es de uso coincide, portanto, com o tempo da sua exposi&ccedil;&atilde;o, at&eacute; que sejam modificadas total ou parcialmente, momento no qual passar&atilde;o a estar vigentes as condi&ccedil;&otilde;es de uso modificadas. </span></p></li>
                        <li><p><strong>22.4</strong><span style="font-weight: 400;"> Se o </span><strong>Utilizador</strong><span style="font-weight: 400;"> n&atilde;o concordar com o teor dos presentes TC, deve abandonar a conex&atilde;o com a p&aacute;gina Web da </span><strong>Empresa</strong><span style="font-weight: 400;"> e/ou n&atilde;o contratar quaisquer dos servi&ccedil;os oferecidos nesta p&aacute;gina, respectivamente.</span></p></li>
                    </ul>
                </li>
                </ol>


                <ol start="23">
                <li><span style="font-weight: 400;"> O </span><strong>Utilizador</strong><span style="font-weight: 400;"> dever&aacute; dirigir-se &agrave; Empresa atrav&eacute;s dos dados de contato constantes da p&aacute;gina Web. </span>
                    <ul>
                        <li><p><strong>23.1</strong><span style="font-weight: 400;"> As comunica&ccedil;&otilde;es entre a </span><strong>Empresa</strong><span style="font-weight: 400;"> e o </span><strong>Utilizador</strong><span style="font-weight: 400;"> realizar-se-&atilde;o em conformidade com os dados de contacto disponibilizados, estabelecendo-se a utiliza&ccedil;&atilde;o do correio electr&oacute;nico como meio preferencial de comunica&ccedil;&otilde;es entre as Partes.</span></p></li>
                        <li><p><strong>23.2</strong><span style="font-weight: 400;"> N&atilde;o obstante, para qualquer comunica&ccedil;&atilde;o que n&atilde;o esteja relacionada com a utiliza&ccedil;&atilde;o e funcionamento do </span><strong>Site</strong><span style="font-weight: 400;"> e/ou do </span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, o </span><strong>Cliente/Utilizador</strong><span style="font-weight: 400;"> dever&aacute; contactar a </span><strong>Empresa</strong><span style="font-weight: 400;">, atrav&eacute;s de carta registada com aviso de recep&ccedil;&atilde;o para: </span></p></li>
                    </ul>
                </li>
                </ol>


                <p><span style="font-weight: 400;">Untold Ventures, Lda. &ndash; Equipa Avisos Legais</span><br />
                <span style="font-weight: 400;">Av. Praia da Vit&oacute;ria, n&ordm; 11, 5&ordm;, 1000-245 Lisboa </span></p>
                <p>&nbsp;</p>
                <p><strong>IV&nbsp;&ndash; Termos e Condi&ccedil;&otilde;es da Garantia</strong></p>
                <ol start="24">
                <li><span style="font-weight: 400;"> N&atilde;o &eacute; dada qualquer garantia impl&iacute;cita de adequa&ccedil;&atilde;o do&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> e documenta&ccedil;&atilde;o associada a determinado fim ou objectivo espec&iacute;fico.</span></li>
                <li><span style="font-weight: 400;"> A&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;"> e os&nbsp;</span><strong>Autores</strong><span style="font-weight: 400;">, tanto quanto lhes &eacute; poss&iacute;vel, procuram assegurar a actualidade e correc&ccedil;&atilde;o da informa&ccedil;&atilde;o que consta das bases de dados do&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, bem como das actualiza&ccedil;&otilde;es dessa informa&ccedil;&atilde;o que venham a ser publicadas nos endere&ccedil;os relativos ao Citius*. Contudo, como relativamente a qualquer&nbsp;obra, n&atilde;o &eacute; poss&iacute;vel garantir a inexist&ecirc;ncia de erros, omiss&otilde;es e imprecis&otilde;es, nem que toda a informa&ccedil;&atilde;o se mantenha </span><span style="font-weight: 400;">sempre </span><span style="font-weight: 400;">actualizada</span><span style="font-weight: 400;">, pelo que, a Empresa n&atilde;o se responsabiliza por qualquer erro, omiss&atilde;o ou imprecis&atilde;o da informa&ccedil;&atilde;o disponibilizada.</span></li>
                <li><span style="font-weight: 400;"> A informa&ccedil;&atilde;o que consta das bases de dados do&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> destina-se exclusivamente &agrave; sua utiliza&ccedil;&atilde;o como ferramenta documental e n&atilde;o possui qualquer valor legal.</span></li>
                <li><span style="font-weight: 400;"> A&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;"> n&atilde;o pode garantir a permanente e futura operacionalidade / acessibilidade &agrave; informa&ccedil;&atilde;o dispon&iacute;vel em&nbsp;sites externos ao&nbsp;</span><strong>Avisos Legais</strong><span style="font-weight: 400;">, mesmo que o&nbsp;acesso a essa informa&ccedil;&atilde;o seja efectuado atrav&eacute;s de hiperliga&ccedil;&otilde;es presentes em liga&ccedil;&otilde;es e/ou&nbsp;documentos presentes no </span><strong>Avisos Legais</strong><span style="font-weight: 400;">,&nbsp;uma vez que as&nbsp;condi&ccedil;&otilde;es de acesso&nbsp;ou o&nbsp;protocolo de liga&ccedil;&atilde;o&nbsp;podem ser alterados, a qualquer momento, por motivos e decis&atilde;o alheios &agrave; vontade da&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;">.</span></li>
                <li><span style="font-weight: 400;"> Nem A&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;">, nem os</span><strong> Autores</strong><span style="font-weight: 400;"> nem qualquer dos demais propriet&aacute;rios de componentes do&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> respondem por quaisquer danos, nomeadamente, danos emergentes, lucros cessantes, interrup&ccedil;&atilde;o de neg&oacute;cios, perda de informa&ccedil;&atilde;o ou dados e outros preju&iacute;zos econ&oacute;micos, financeiros ou pecuni&aacute;rios decorrentes da utiliza&ccedil;&atilde;o e/ou da impossibilidade de utiliza&ccedil;&atilde;o do&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">.</span></li>
                <li><span style="font-weight: 400;"> Em qualquer caso, a responsabilidade integral da&nbsp;</span><strong>Empresa</strong><span style="font-weight: 400;">, dos </span><strong>Autores</strong><span style="font-weight: 400;"> ou de qualquer dos demais propriet&aacute;rios de componentes do&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">, eventualmente emergente da utiliza&ccedil;&atilde;o do Servi&ccedil;o, limita-se, no m&aacute;ximo, &agrave; quantia que o&nbsp;</span><strong>Cliente </strong><span style="font-weight: 400;">tiver pago pela subscri&ccedil;&atilde;o mais recente do plano de utiliza&ccedil;&atilde;o do Servi&ccedil;o.</span></li>
                <li><span style="font-weight: 400;"> A garantia limitada n&atilde;o poder&aacute; ser invocada se a eventual defici&ecirc;ncia do&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;"> resultar de acidente, de uso incorrecto ou de utiliza&ccedil;&atilde;o abusiva ou inadequada.</span></li>
                </ol>
                <p>&nbsp;</p>
                <p><span style="font-weight: 400;">Quaisquer d&uacute;vidas acerca dos presentes TC poder&atilde;o ser esclarecidas atrav&eacute;s do envio de </span></p>
                <p><span style="font-weight: 400;">e-mail para: geral@avisoslegais.pt</span></p>
                <p>&nbsp;</p>
                <p><span style="font-weight: 400;">&ldquo;</span><strong>Avisos Legais</strong><span style="font-weight: 400;">&rdquo; e o seu log&oacute;tipo s&atilde;o marcas registadas.</span></p>
                <p><span style="font-weight: 400;">O&nbsp;</span><strong>Servi&ccedil;o</strong><span style="font-weight: 400;">&nbsp;est&aacute; ou vai ser depositado na ASSOFT &ndash; Associa&ccedil;&atilde;o Portuguesa de Software (</span><a target='_blank' href="http://www.assoft.pt/"><span style="font-weight: 400;">www.assoft.pt</span></a><span style="font-weight: 400;">).</span></p>

            </div>
            </div>

        </div>
    </div>
</div>
